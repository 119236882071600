// Germany
export const locale = {
  lang: "de",
  data: {
    COMMON: {
      BUTTON: {
        CANCEL: "Abbrechen",
        SUBMIT: "Einreichen",
      },
      VALIDATION: {
        SOMETHING_WENT_WRONG: "Ups, es scheint, dass etwas schief gelaufen ist! Bitte versuche es erneut.",
      },
    },
    NAVBAR: {
      HELLO: "Hallo",
      MY_DETAILS: "Meine Details",
      MY_CONTACT_METHODS: "Meine Kontaktmethoden",
      LOGOUT: "Abmelden",
      SELECT_LANGUAGE: "Sprache auswählen",
      MENU: "Menü",
      CLOSE: "Schließen",
      SETTINGS: "Einstellungen",
      TERMSANDCONDITIONS: "Allgemeine Geschäftsbedingungen",
      LANGUAGE: {
        ENLABEL: "Englisch",
        CHLABEL: "Chinesisch",
        ESLABEL: "Spanisch",
        JALABEL: "Japanisch",
        DELABEL: "Deutsch",
        FRLABEL: "Französisch",
      },
      MENU_ITEM: {
        CURRENT_PLACEMENT: "Meine aktuelle Platzierung",
        COMPLIANCE: "Meine Konformität",
        TIMESHEETS: "Meine Zeitpläne",
        EXPENSES: "Meine Ausgaben",
      },
    },
    AUTH: {
      LOGIN: {
        BUTTON: {
          SIGN_IN: "Einloggen",
        },
        LABEL: {
          EMAIL: "E-Mail",
          PASSWORD: "Passwort",
          FORGOT_PASSWORD: "Passwort vergessen?",
        },
        VALIDATION: {
          EMAIL_INVALID: "Ungültige E-Mail-Adresse",
          EMAIL_MAX_LENGTH: "Die E-Mail darf nicht mehr als {{value}} Zeichen enthalten",
          EMAIL_REQUIRED: "E-Mail ist erforderlich",
          LOGIN_FAILED: "Die Anmeldeinformationen sind falsch",
          PASSWORD_MAX_LENGTH: "Das Passwort darf nicht mehr als {{value}} Zeichen enthalten",
          PASSWORD_MIN_LENGTH: "Das Passwort muss mindestens {{value}} Zeichen enthalten",
          PASSWORD_REQUIRED: "Passwort ist erforderlich",
        }
      },
      FORGOT_PASSWORD: {
        TITLE_DESCRIPTION: "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen",
        TITLE_FORGOTTEN_PASSWORD: "Passwort vergessen?",
        BUTTON: {
          OK_GOT_IT: "Ok, verstanden!",
        },
        LABEL: {
          EMAIL: "E-Mail",
          EMAIL_SENT_MESSAGE1: "Wir haben eine E-Mail an die von Ihnen angegebene Adresse gesendet, um Ihr Passwort zurückzusetzen.",
          EMAIL_SENT_MESSAGE2: "Wenn Sie die E-Mail nicht erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner und stellen Sie sicher, dass die eingegebene E-Mail-Adresse korrekt ist.",
          EMAIL_SENT_TITLE: "Wiederherstellungs-E-Mail gesendet!",
          SUCCESS: "Ihr Konto wurde erfolgreich zurückgesetzt.",
        },
        VALIDATION: {
          EMAIL_INVALID: "Ungültige E-Mail-Adresse",
          EMAIL_MAX_LENGTH: "Die E-Mail darf nicht mehr als {{value}} Zeichen enthalten",
          EMAIL_REQUIRED: "E-Mail ist erforderlich",
        }
      },
      RESET_PASSWORD: {
        TITLE_DESCRIPTION: "Geben Sie die Details des neuen Passworts ein",
        TITLE_RESET_PASSWORD: "Passwort zurücksetzen",
        BUTTON: {

        },
        LABEL: {
          CONFIRM_NEW_PASSWORD: "Neues Passwort bestätigen",
          CREATE_NEW_PASSWORD: "Neues Passwort erstellen",
          OLD_PASSWORD: "Altes Passwort",
          PASSWORD: "Passwort",
        },
        VALIDATION: {
          PASSWORDS_DONT_MATCH: "'Passwort' und 'Neues Passwort bestätigen' stimmen nicht überein",
          PASSWORD_MAX_LENGTH: "Das Passwort darf nicht mehr als {{value}} Zeichen enthalten",
          PASSWORD_MIN_LENGTH: "Das Passwort muss mindestens {{value}} Zeichen enthalten",
          PASSWORD_PATTERN: "Muss Groß- und Kleinbuchstaben, eine Zahl und eines der folgenden Zeichen enthalten: -+_!@#$%^&*.,?",
          PASSWORD_REQUIRED: "Passwort ist erforderlich",
          REGISTRATION_DETAILS_INCORRECT: "Registrierungsdetails sind falsch"
        }
      }
    },
  }
};
