import { AbstractControl } from '@angular/forms';
import { TimesheetFrequencyType } from 'src/app/_mx/enums/timesheet-frequency-type.enum';
import { NonWorkingDays } from 'src/app/_mx/models/calendar-ribbon/non-working-days.model';
import { ITime, Time } from 'src/app/_mx/models/time.model';
import { ITimesheetDay, TimesheetDay } from 'src/app/_mx/models/timesheet/timesheet-day.model';
import { ITimesheetDetail, TimesheetDetail } from 'src/app/_mx/models/timesheet/timesheet-detail.model';
import { TimesheetRate } from 'src/app/_mx/models/timesheet/timesheet-rates.model';
import { TimesheetView } from 'src/app/_mx/models/timesheet/timesheet-view.model';
import { ITimesheet } from 'src/app/_mx/models/timesheet/timesheet.model';
import { DateRange } from 'src/app/_mx/models/date-range.model';

export class TimesheetHelperService {

  private readonly daysOfWeek: string[] =  ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"]; 

  constructor() { }

  public getTimesheetFrequencyText(timesheetDetail: ITimesheetDetail): string {
    if (!timesheetDetail.frequency) //TODO: update Spec Tests
      return "";

    const frequencyType = timesheetDetail.frequency.frequencyType;
    switch(frequencyType) {
      case TimesheetFrequencyType.Weekly:
        return "Week";
      case TimesheetFrequencyType.Monthly:
        return "Month";
      case TimesheetFrequencyType.MultiWeek:
        return "Weeks";
      default:
        return "";
    }
  }

  public getDayUnits(rate: ITimesheetDay, day: Date): ITime {
    const dayOfWeek = day.getDay();

    switch(dayOfWeek)
    {
      case 0:
        return rate.sundayUnits;
      case 1:
        return rate.mondayUnits;
      case 2:
        return rate.tuesdayUnits;
      case 3:
        return rate.wednesdayUnits;
      case 4:
        return rate.thursdayUnits;
      case 5:
        return rate.fridayUnits;
      case 6:
        return rate.saturdayUnits;
      default:
        return null;
    }
  }

  public calculateNonWorkingDay(nonWorkingDays: NonWorkingDays, day: Date): boolean {
    const dayOfWeek = day.getDay();

    switch(dayOfWeek)
    {
      case 0:
        return nonWorkingDays.sunday;
      case 1:
        return nonWorkingDays.monday;
      case 2:
        return nonWorkingDays.tuesday;
      case 3:
        return nonWorkingDays.wednesday;
      case 4:
        return nonWorkingDays.thursday;
      case 5:
        return nonWorkingDays.friday;
      case 6:
        return nonWorkingDays.saturday;
      default:
        return false;
    }
  }

  public isFinishedStatus(timesheet: ITimesheet): boolean {
    return timesheet.status === 'Submitted' || 
           timesheet.status === 'Approved' || 
           timesheet.status === 'Validated' ||
           timesheet.status === 'Complete';
  }

  public getDays(timesheetDetail: TimesheetDetail) : string[] {
    let startDay = 1;
    let days: string[] = [];
    if (timesheetDetail.frequency != null)
      startDay = timesheetDetail.frequency.startDay;

      for (let i = 1; i < 8; i++) {
        days.push(this.daysOfWeek[startDay]);
        startDay = startDay == 6 ? 0 : startDay+1;
      }

    return days;
  }
  
  public calculateTotalTimeUnits(timesheetView: TimesheetView): Time {
    let totalMins = 0;

    if (timesheetView.day1StartTime && timesheetView.day1EndTime)
      totalMins += this.timeDiff(timesheetView.day1StartTime, timesheetView.day1EndTime);
    if (timesheetView.day2StartTime && timesheetView.day2EndTime)
      totalMins += this.timeDiff(timesheetView.day2StartTime, timesheetView.day2EndTime);
    if (timesheetView.day3StartTime && timesheetView.day3EndTime)
      totalMins += this.timeDiff(timesheetView.day3StartTime, timesheetView.day3EndTime);
    if (timesheetView.day4StartTime && timesheetView.day4EndTime)
      totalMins += this.timeDiff(timesheetView.day4StartTime, timesheetView.day4EndTime);
    if (timesheetView.day5StartTime && timesheetView.day5EndTime)
      totalMins += this.timeDiff(timesheetView.day5StartTime, timesheetView.day5EndTime);
    if (timesheetView.day6StartTime && timesheetView.day6EndTime)
      totalMins += this.timeDiff(timesheetView.day6StartTime, timesheetView.day6EndTime);
    if (timesheetView.day7StartTime && timesheetView.day7EndTime)
      totalMins += this.timeDiff(timesheetView.day7StartTime, timesheetView.day7EndTime);

    const hours = Math.floor(totalMins / 60);
    const mins = totalMins - (hours * 60);
    
    return new Time(null, hours, mins);
  }

  public mapTimesheetViewsToTimesheetDays(timesheetViews: Array<TimesheetView>, timesheetDetail: TimesheetDetail): Array<ITimesheetDay> {
    let timesheetDays: ITimesheetDay[] = [];

    timesheetViews.forEach(timesheetView => {
      const timesheetDetailDay = timesheetDetail.days.find(x => x.rate.id == timesheetView.rateId &&
                                                                x.rate.rateType == timesheetView.rateType &&
                                                                new Date(x.startDate).toDateString() == new Date(timesheetView.start).toDateString() &&
                                                                new Date(x.endDate).toDateString() == new Date(timesheetView.end).toDateString());
      if (timesheetDetailDay) {
        const timesheetDay = new TimesheetDay(
          timesheetDetailDay.id,
          this.getMappedUnits('Mon', timesheetView, timesheetDetail),
          this.getMappedUnits('Tue', timesheetView, timesheetDetail),
          this.getMappedUnits('Wed', timesheetView, timesheetDetail),
          this.getMappedUnits('Thur', timesheetView, timesheetDetail),
          this.getMappedUnits('Fri', timesheetView, timesheetDetail),
          this.getMappedUnits('Sat', timesheetView, timesheetDetail),
          this.getMappedUnits('Sun', timesheetView, timesheetDetail),
          timesheetDetailDay.startDate,
          timesheetDetailDay.endDate,
          new TimesheetRate(timesheetDetailDay.rate.id, timesheetDetailDay.rate.name, timesheetDetailDay.rate.rateType),
        )
  
        timesheetDays.push(timesheetDay);
      }
    });

    return timesheetDays;
  }

  public dayFormsToUpdate(dayForms: Array<AbstractControl>, timesheetWeeks: Array<DateRange>, timesheetDetail: ITimesheetDetail): Array<ITimesheetDay> {
    let timesheetDays: Array<ITimesheetDay> = new Array<ITimesheetDay>();
    
    dayForms.forEach(dayForm => {
      const day = dayForm.value;
      const week = timesheetWeeks.find(x => x.start <= day.date && x.end >= day.date);

      day.rates.forEach(rate => {
        let timesheetDay = timesheetDays.find(x =>
          x.startDate.getTime() === week.start.getTime() && 
          x.endDate.getTime() === week.end.getTime() && 
          x.rate.id == rate.rateId
        );
        
        if (!timesheetDay) {
            timesheetDay = timesheetDetail.days.find(x =>
                x.startDate.getTime() === week.start.getTime() &&
                x.endDate.getTime() === week.end.getTime() &&
                x.rate.id == rate.rateId
            );
            timesheetDays.push(timesheetDay);
        }
        
        let dayUnit = this.getDayUnits(timesheetDay, day.date);

        if (timesheetDay.rate.rateType == 'Daily') {
          dayUnit.dayUnits = rate.dayUnits;
        }
        else {
          dayUnit.hourUnits = rate.hourUnits;
          dayUnit.minuteUnits = rate.minuteUnits;
        }
      });

      let timesheetDay = timesheetDetail.days.filter(x =>
        new Date(x.startDate).toDateString() == week.start.toDateString() &&
        new Date(x.endDate).toDateString() == week.end.toDateString())[0];

      let dayUnit = this.getDayUnits(timesheetDay, day.date);
      
      dayUnit.startTime = day.startTime;
      dayUnit.endTime = day.endTime;
    });

    return timesheetDays;
  }

  public formatTimesheetDates(timesheets: ITimesheetDay[]): ITimesheetDay[] {
    var formatedTimesheets: ITimesheetDay[] = [];
    timesheets.forEach(timesheet => {
      formatedTimesheets.push(new TimesheetDay(
        timesheet.id,
        timesheet.mondayUnits,
        timesheet.tuesdayUnits,
        timesheet.wednesdayUnits,
        timesheet.thursdayUnits,
        timesheet.fridayUnits,
        timesheet.saturdayUnits,
        timesheet.sundayUnits,
        this.setMinutesWithOffset(timesheet.startDate),
        this.setMinutesWithOffset(timesheet.endDate),
        timesheet.rate
      ));
    });
    
    return formatedTimesheets;
  }

  private setMinutesWithOffset(date: Date) {
    var offsetDate = new Date(date.getTime());
    offsetDate.setMinutes(offsetDate.getMinutes() - (offsetDate.getTimezoneOffset()));
    return offsetDate;
  }

  private getMappedUnits(day: string, timesheetView: TimesheetView, timesheetDetail: TimesheetDetail) : Time {
    const dayOfWeek = this.getDays(timesheetDetail).findIndex(d => d == day);
   
    let startTime: Date = null;
    if (timesheetView[`day${dayOfWeek + 1}StartTime`]) {
      startTime = this.getMappedTimeValue(timesheetView, `day${dayOfWeek + 1}StartTime`);
    }
    
    let endTime: Date = null;
    if (timesheetView[`day${dayOfWeek + 1}EndTime`]) {
      endTime = this.getMappedTimeValue(timesheetView, `day${dayOfWeek + 1}EndTime`);
    }

    return new Time(
      timesheetView[`day${dayOfWeek + 1}DayUnits`],
      timesheetView[`day${dayOfWeek + 1}HourUnits`],
      timesheetView[`day${dayOfWeek + 1}MinuteUnits`],
      startTime,
      endTime
    );
  }

  public getMappedTimeValue(timesheetView: TimesheetView, timeField: string): Date {
    const timeParts = this.splitTime((timesheetView[timeField] as string));
    const date = new Date(0);
    date.setUTCHours(timeParts[0], timeParts[1]);
    return date;
  }

  private splitTime(time: string): number[] {
    const timeParts = time.split(':');
    const hours = Number.parseInt(timeParts[0]);
    const mins = Number.parseInt(timeParts[1]);
    return [
      hours,
      mins
    ]
  }

  private timeDiff(start: string, end: string): number {
    const startParts = this.splitTime(start);
    const endParts = this.splitTime(end);

    return  ((endParts[0] * 60) + endParts[1]) - ((startParts[0] * 60) + startParts[1]);
  }
}
