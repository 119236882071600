// USA
export const locale = {
  lang: "en",
  data: {
    COMMON: {
      BUTTON: {
        CANCEL: "Cancel",
        SUBMIT: "Submit",
      },
      VALIDATION: {
        SOMETHING_WENT_WRONG: "Oops looks like something went wrong! Please try again.",
      },
    },
    NAVBAR: {
      HELLO: "Hello",
      MY_DETAILS: "My Details",
      MY_CONTACT_METHODS: "My Contact Methods",
      MY_DATA_CONSENTS: "My Data Consents",
      LOGOUT: "Logout",
      SELECT_LANGUAGE: "Select Language",
      MENU: "Menu",
      CLOSE: "Close",
      SETTINGS: "Settings",
      TERMSANDCONDITIONS: "Terms & Conditions",
      LANGUAGE: {
        ENLABEL: "English",
        CHLABEL: "Chinese",
        ESLABEL: "Spanish",
        JALABEL: "Japanese",
        DELABEL: "German",
        FRLABEL: "French",
      },
      MENU_ITEM: {
        CURRENT_PLACEMENT: "My Current Placement",
        COMPLIANCE: "My Compliance",
        TIMESHEETS: "My Timesheets",
        EXPENSES: "My Expenses",
      },
    },
    AUTH: {
      LOGIN: {
        BUTTON: {
          SIGN_IN: "Sign In",
        },
        LABEL: {
          EMAIL: "Email",
          PASSWORD: "Password",
          FORGOT_PASSWORD: "Forgot Password?",
        },
        VALIDATION: {
          EMAIL_INVALID: "Invalid Email address",
          EMAIL_MAX_LENGTH: "Email must not exceed {{value}} characters long",
          EMAIL_REQUIRED: "Email is required",
          LOGIN_FAILED: 'The login details are incorrect',
          PASSWORD_MAX_LENGTH: "Password must not exceed {{value}} characters long",
          PASSWORD_MIN_LENGTH: "Password must be at least {{value}} characters long",
          PASSWORD_REQUIRED: "Password is required",
        }
      },
      FORGOT_PASSWORD: {
        TITLE_DESCRIPTION: "Enter your email to reset your password",
        TITLE_FORGOTTEN_PASSWORD: "Forgotten Password?",
        BUTTON: {
          OK_GOT_IT: "Ok, got it!",
        },
        LABEL: {
          EMAIL: "Email",
          EMAIL_SENT_MESSAGE1: "We've sent an email to the address you specified to reset your password.",
          EMAIL_SENT_MESSAGE2: "If you didn't receive it, please check your junk folder and also verify that the email address entered was correct.",
          EMAIL_SENT_TITLE: "Recovery Email Sent!",
          SUCCESS: "Your account has been successfully reset.",
        },
        VALIDATION: {
          EMAIL_INVALID: "Invalid Email address",
          EMAIL_MAX_LENGTH: "Email must not exceed {{value}} characters long",
          EMAIL_REQUIRED: "Email is required",
        }
      },
      RESET_PASSWORD: {
        TITLE_DESCRIPTION: "Enter new password details",
        TITLE_RESET_PASSWORD: "Reset Password",
        BUTTON: {

        },
        LABEL: {
          CONFIRM_NEW_PASSWORD: "Confirm new password",
          CREATE_NEW_PASSWORD: "Create new password",
          OLD_PASSWORD: "Old Password",
          PASSWORD: "Password",
        },
        VALIDATION: {
          PASSWORDS_DONT_MATCH: "'Passsword' and 'Confirm Password' didn't match",
          PASSWORD_MAX_LENGTH: "Password must not exceed {{value}} characters long",
          PASSWORD_MIN_LENGTH: "Password must be at least {{value}} characters long",
          PASSWORD_PATTERN: "Must contain both uppercase & lowercase letters, a number and one of: -+_!@#$%^&*.,?",
          PASSWORD_REQUIRED: "Password is required",
          REGISTRATION_DETAILS_INCORRECT: "The registration details are incorrect"
        }
      }
    },
  }
};

