// Japan
export const locale = {
  lang: "ja",
  data: {
    COMMON: {
      BUTTON: {
        CANCEL: "キャンセル",
        SUBMIT: "送信",
      },
      VALIDATION: {
        SOMETHING_WENT_WRONG: "おっと、何かがうまくいかなかったようです！ もう一度お試しください。",
      },
    },
    NAVBAR: {
      HELLO: "こんにちは",
      MY_DETAILS: "私の詳細",
      MY_CONTACT_METHODS: "私の連絡方法",
      LOGOUT: "ログアウト",
      SELECT_LANGUAGE: "言語を選択",
      MENU: "メニュー",
      CLOSE: "閉じる",
      SETTINGS: "設定",
      TERMSANDCONDITIONS: "利用規約",
      LANGUAGE: {
        ENLABEL: "英語",
        CHLABEL: "中国語",
        ESLABEL: "スペイン語",
        JALABEL: "日本語",
        DELABEL: "ドイツ語",
        FRLABEL: "フランス語",
      },
      MENU_ITEM: {
        CURRENT_PLACEMENT: "現在の配置",
        COMPLIANCE: "コンプライアンス",
        TIMESHEETS: "タイムシート",
        EXPENSES: "経費",
      },
    },
    AUTH: {
      LOGIN: {
        BUTTON: {
          SIGN_IN: "サインイン",
        },
        LABEL: {
          EMAIL: "メール",
          PASSWORD: "パスワード",
          FORGOT_PASSWORD: "パスワードを忘れた？",
        },
        VALIDATION: {
          EMAIL_INVALID: "無効なメールアドレス",
          EMAIL_MAX_LENGTH: "メールは{{value}}文字を超えてはなりません",
          EMAIL_REQUIRED: "メールは必須です",
          LOGIN_FAILED: "ログイン情報が正しくありません",
          PASSWORD_MAX_LENGTH: "パスワードは{{value}}文字を超えてはなりません",
          PASSWORD_MIN_LENGTH: "パスワードは少なくとも{{value}}文字を含む必要があります",
          PASSWORD_REQUIRED: "パスワードは必須です",
        }
      },
      FORGOT_PASSWORD: {
        TITLE_DESCRIPTION: "パスワードをリセットするためのメールを入力してください",
        TITLE_FORGOTTEN_PASSWORD: "パスワードを忘れましたか？",
        BUTTON: {
          OK_GOT_IT: "了解、わかりました！",
        },
        LABEL: {
          EMAIL: "メール",
          EMAIL_SENT_MESSAGE1: "パスワードをリセットするためのメールを指定されたアドレスに送りました。",
          EMAIL_SENT_MESSAGE2: "受信していない場合は、迷惑メールフォルダを確認し、入力されたメールアドレスが正しいことを確認してください。",
          EMAIL_SENT_TITLE: "回復メールが送信されました！",
          SUCCESS: "アカウントは正常にリセットされました。",
        },
        VALIDATION: {
          EMAIL_INVALID: "無効なメールアドレス",
          EMAIL_MAX_LENGTH: "メールは{{value}}文字を超えてはなりません",
          EMAIL_REQUIRED: "メールは必須です",
        }
      },
      RESET_PASSWORD: {
        TITLE_DESCRIPTION: "新しいパスワードの詳細を入力してください",
        TITLE_RESET_PASSWORD: "パスワードをリセット",
        BUTTON: {

        },
        LABEL: {
          CONFIRM_NEW_PASSWORD: "新しいパスワードを確認",
          CREATE_NEW_PASSWORD: "新しいパスワードを作成",
          OLD_PASSWORD: "古いパスワード",
          PASSWORD: "パスワード",
        },
        VALIDATION: {
          PASSWORDS_DONT_MATCH: "'パスワード' と '新しいパスワードを確認' が一致しません",
          PASSWORD_MAX_LENGTH: "パスワードは{{value}}文字を超えてはなりません",
          PASSWORD_MIN_LENGTH: "パスワードは少なくとも{{value}}文字を含む必要があります",
          PASSWORD_PATTERN: "大文字と小文字のアルファベット、数字、および次のいずれかを含む必要があります: -+_!@#$%^&*.,?",
          PASSWORD_REQUIRED: "パスワードは必須です",
          REGISTRATION_DETAILS_INCORRECT: "登録の詳細が間違っています"
        }
      }
    },
  }
};
