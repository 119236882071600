import { BaseModel } from "src/app/_metronic/shared/crud-table/models/base.model";

export interface ITimesheetRate extends BaseModel {
    name : string,
    rateType : string,
}

export class TimesheetRate implements ITimesheetRate {
    constructor(
        public id : any = '',
        public name : string = '',
        public rateType : string = '',
    ) {}
}