// France
export const locale = {
  lang: "fr",
  data: {
    COMMON: {
      BUTTON: {
        CANCEL: "Annuler",
        SUBMIT: "Soumettre",
      },
      VALIDATION: {
        SOMETHING_WENT_WRONG: "Oups, il semble que quelque chose s'est mal passé! Veuillez réessayer.",
      },
    },
    NAVBAR: {
      HELLO: "Bonjour",
      MY_DETAILS: "Mes Détails",
      MY_CONTACT_METHODS: "Mes Méthodes de Contact",
      LOGOUT: "Déconnexion",
      SELECT_LANGUAGE: "Choisir la Langue",
      MENU: "Menu",
      CLOSE: "Fermer",
      SETTINGS: "Paramètres",
      TERMSANDCONDITIONS: "Conditions Générales",
      LANGUAGE: {
        ENLABEL: "Anglais",
        CHLABEL: "Chinois",
        ESLABEL: "Espagnol",
        JALABEL: "Japonais",
        DELABEL: "Allemand",
        FRLABEL: "Français",
      },
      MENU_ITEM: {
        CURRENT_PLACEMENT: "Mon Placement Actuel",
        COMPLIANCE: "Ma Conformité",
        TIMESHEETS: "Mes Feuilles de Temps",
        EXPENSES: "Mes Dépenses",
      },
    },
    AUTH: {
      LOGIN: {
        BUTTON: {
          SIGN_IN: "Se Connecter",
        },
        LABEL: {
          EMAIL: "E-mail",
          PASSWORD: "Mot de Passe",
          FORGOT_PASSWORD: "Mot de Passe Oublié?",
        },
        VALIDATION: {
          EMAIL_INVALID: "Adresse E-mail Invalide",
          EMAIL_MAX_LENGTH: "L'e-mail ne doit pas dépasser {{value}} caractères",
          EMAIL_REQUIRED: "L'e-mail est requis",
          LOGIN_FAILED: "Les informations de connexion sont incorrectes",
          PASSWORD_MAX_LENGTH: "Le mot de passe ne doit pas dépasser {{value}} caractères",
          PASSWORD_MIN_LENGTH: "Le mot de passe doit contenir au moins {{value}} caractères",
          PASSWORD_REQUIRED: "Le mot de passe est requis",
        }
      },
      FORGOT_PASSWORD: {
        TITLE_DESCRIPTION: "Entrez votre e-mail pour réinitialiser votre mot de passe",
        TITLE_FORGOTTEN_PASSWORD: "Mot de Passe Oublié?",
        BUTTON: {
          OK_GOT_IT: "D'accord, j'ai compris!",
        },
        LABEL: {
          EMAIL: "E-mail",
          EMAIL_SENT_MESSAGE1: "Nous avons envoyé un e-mail à l'adresse que vous avez spécifiée pour réinitialiser votre mot de passe.",
          EMAIL_SENT_MESSAGE2: "Si vous ne l'avez pas reçu, veuillez vérifier votre dossier de courriers indésirables et vérifier également que l'adresse e-mail entrée était correcte.",
          EMAIL_SENT_TITLE: "E-mail de Récupération Envoyé!",
          SUCCESS: "Votre compte a été réinitialisé avec succès.",
        },
        VALIDATION: {
          EMAIL_INVALID: "Adresse E-mail Invalide",
          EMAIL_MAX_LENGTH: "L'e-mail ne doit pas dépasser {{value}} caractères",
          EMAIL_REQUIRED: "L'e-mail est requis",
        }
      },
      RESET_PASSWORD: {
        TITLE_DESCRIPTION: "Entrez les détails du nouveau mot de passe",
        TITLE_RESET_PASSWORD: "Réinitialiser le Mot de Passe",
        BUTTON: {

        },
        LABEL: {
          CONFIRM_NEW_PASSWORD: "Confirmer le nouveau mot de passe",
          CREATE_NEW_PASSWORD: "Créer un nouveau mot de passe",
          OLD_PASSWORD: "Ancien Mot de Passe",
          PASSWORD: "Mot de Passe",
        },
        VALIDATION: {
          PASSWORDS_DONT_MATCH: "'Mot de Passe' et 'Confirmer le Mot de Passe' ne correspondent pas",
          PASSWORD_MAX_LENGTH: "Le mot de passe ne doit pas dépasser {{value}} caractères",
          PASSWORD_MIN_LENGTH: "Le mot de passe doit contenir au moins {{value}} caractères",
          PASSWORD_PATTERN: "Doit contenir des lettres majuscules et minuscules, un chiffre et l'un des suivants : -+_!@#$%^&*.,?",
          PASSWORD_REQUIRED: "Le mot de passe est requis",
          REGISTRATION_DETAILS_INCORRECT: "Les détails d'inscription sont incorrects"
        }
      }
    },
  }
};
