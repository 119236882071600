import { HostListener, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  public height: number;
  public width: number;
  public isMobile: boolean = false;

  private readonly mobileSizeThreshold: number = 576;

  constructor() { 
    this.resize();
  }

  public resize(): void {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.isMobile = (this.width <= this.mobileSizeThreshold);
  }

}
