import { DateFormatConstants } from "src/app/_mx/constants/DateFormatConstants";
import { LocaleConstants } from "src/app/_mx/constants/LocaleConstants";

export class DateHelperService {

  constructor() { }

  public getDaysForDateRange(startDate: Date, endDate: Date): Array<Date> {
    let days = new Array<Date>();

    let current = startDate;
    while (current < endDate) {
      days.push(current);
      current = new Date(current.getFullYear(), current.getMonth(), current.getDate() + 1);
    }
    days.push(current);

    return days;
  }

  public getOrdinalIndicator(day: Date): string {
    const dayNumber = day.getDate();

    if (dayNumber === 1 || dayNumber === 21 || dayNumber === 31)
      return 'st';
    if (dayNumber === 2 || dayNumber === 22)
      return 'nd';
    if (dayNumber === 3 || dayNumber === 23)
      return 'rd';

    return 'th';
  }

  public getDatePipeFromLocale(): string {
    switch (navigator.language) {
      case LocaleConstants.enGB:
      case LocaleConstants.en: {
        return DateFormatConstants.en
      }
      case LocaleConstants.enUS: {
        return DateFormatConstants.enUS
      }
      default: {
        return DateFormatConstants.default
      }
    }
  }
}
