// Spain
export const locale = {
  lang: "es",
  data: {
    COMMON: {
      BUTTON: {
        CANCEL: "Cancelar",
        SUBMIT: "Enviar",
      },
      VALIDATION: {
        SOMETHING_WENT_WRONG: "¡Ups, parece que algo salió mal! Por favor, inténtalo de nuevo.",
      },
    },
    NAVBAR: {
      HELLO: "Hola",
      MY_DETAILS: "Mis Detalles",
      MY_CONTACT_METHODS: "Mis Métodos de Contacto",
      LOGOUT: "Cerrar sesión",
      SELECT_LANGUAGE: "Seleccionar Idioma",
      MENU: "Menú",
      CLOSE: "Cerrar",
      SETTINGS: "Ajustes",
      TERMSANDCONDITIONS: "Términos y Condiciones",
      LANGUAGE: {
        ENLABEL: "Inglés",
        CHLABEL: "Chino",
        ESLABEL: "Español",
        JALABEL: "Japonés",
        DELABEL: "Alemán",
        FRLABEL: "Francés",
      },
      MENU_ITEM: {
        CURRENT_PLACEMENT: "Mi Ubicación Actual",
        COMPLIANCE: "Mi Conformidad",
        TIMESHEETS: "Mis Hojas de Tiempo",
        EXPENSES: "Mis Gastos",
      },
    },
    AUTH: {
      LOGIN: {
        BUTTON: {
          SIGN_IN: "Iniciar Sesión",
        },
        LABEL: {
          EMAIL: "Correo Electrónico",
          PASSWORD: "Contraseña",
          FORGOT_PASSWORD: "¿Olvidaste tu contraseña?",
        },
        VALIDATION: {
          EMAIL_INVALID: "Dirección de correo electrónico no válida",
          EMAIL_MAX_LENGTH: "El correo electrónico no debe exceder {{value}} caracteres",
          EMAIL_REQUIRED: "El correo electrónico es obligatorio",
          LOGIN_FAILED: "La información de inicio de sesión es incorrecta",
          PASSWORD_MAX_LENGTH: "La contraseña no debe exceder {{value}} caracteres",
          PASSWORD_MIN_LENGTH: "La contraseña debe contener al menos {{value}} caracteres",
          PASSWORD_REQUIRED: "La contraseña es obligatoria",
        }
      },
      FORGOT_PASSWORD: {
        TITLE_DESCRIPTION: "Ingrese su correo electrónico para restablecer su contraseña",
        TITLE_FORGOTTEN_PASSWORD: "¿Olvidaste tu contraseña?",
        BUTTON: {
          OK_GOT_IT: "¡De acuerdo, lo tengo!",
        },
        LABEL: {
          EMAIL: "Correo Electrónico",
          EMAIL_SENT_MESSAGE1: "Hemos enviado un correo electrónico a la dirección que especificó para restablecer su contraseña.",
          EMAIL_SENT_MESSAGE2: "Si no lo ha recibido, verifique su carpeta de correo no deseado y asegúrese de que la dirección de correo electrónico ingresada sea correcta.",
          EMAIL_SENT_TITLE: "¡Correo de recuperación enviado!",
          SUCCESS: "Su cuenta ha sido restablecida con éxito.",
        },
        VALIDATION: {
          EMAIL_INVALID: "Dirección de correo electrónico no válida",
          EMAIL_MAX_LENGTH: "El correo electrónico no debe exceder {{value}} caracteres",
          EMAIL_REQUIRED: "El correo electrónico es obligatorio",
        }
      },
      RESET_PASSWORD: {
        TITLE_DESCRIPTION: "Ingrese los detalles de la nueva contraseña",
        TITLE_RESET_PASSWORD: "Restablecer Contraseña",
        BUTTON: {

        },
        LABEL: {
          CONFIRM_NEW_PASSWORD: "Confirmar nueva contraseña",
          CREATE_NEW_PASSWORD: "Crear nueva contraseña",
          OLD_PASSWORD: "Contraseña anterior",
          PASSWORD: "Contraseña",
        },
        VALIDATION: {
          PASSWORDS_DONT_MATCH: "'Contraseña' y 'Confirmar nueva contraseña' no coinciden",
          PASSWORD_MAX_LENGTH: "La contraseña no debe exceder {{value}} caracteres",
          PASSWORD_MIN_LENGTH: "La contraseña debe contener al menos {{value}} caracteres",
          PASSWORD_PATTERN: "Debe contener letras mayúsculas y minúsculas, un número y uno de los siguientes caracteres: -+_!@#$%^&*.,?",
          PASSWORD_REQUIRED: "La contraseña es obligatoria",
          REGISTRATION_DETAILS_INCORRECT: "Los detalles de registro son incorrectos"
        }
      }
    },
  }
};
