export interface ITime {
    dayUnits : number,
    hourUnits : number,
    minuteUnits: number,
    startTime?: Date,
    endTime?: Date
}

export class Time implements ITime {
    constructor(
        public dayUnits : number = 0,
        public hourUnits : number = 0,
        public minuteUnits : number = 0,
        public startTime?: Date,
        public endTime?: Date
    ) {}
}