import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";
import { AppConfigService } from 'src/app/_primeng/services/appconfig.service';
import { AuthModel, AuthRequestModel } from '../../models/auth.model';
import { ResetPasswordModel } from '../../models/reset-password.model';
import { AESService } from 'src/app/_primeng/services/encryption/aes-service';
import { Theme } from 'src/app/_primeng/models/theme/theme.model';
import { IUserModel } from '../../models/user.model';


@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient,
    private appConfig: AppConfigService) {
  }

  // * === === === === === === === === === === === === === ===
  // * PUBLIC METHODS
  // * === === === === === === === === === === === === === ===

  public login(email: string, password: string): Observable<any> {
    const body = new AuthRequestModel();
    body.password = AESService.encrypt(password);
    body.username = email;
    body.scope = "candidate_portal";

    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    return this.http.post<AuthModel>(`${this.appConfig.getConfig().apiUrl}/auth/token`, body, options);
  }

  public resetPassword(access_token: string, resetPassword: ResetPasswordModel): Observable<boolean> {
    return this.httpPost(`/auth/resetpassword`, resetPassword, access_token).pipe(
      map((success: boolean) => {
        return success;
      })
    );
  }

  public forgotPassword(email: string): Observable<boolean> {
    const body = { email: email };
    return this.httpPost(`/auth/forgotpassword`, body).pipe(
      map((success: boolean) => {
        return success;
      })
    );
  }

  public getTheme(hash: string): Observable<Theme> {
    let params = '';
    if (hash)
      params = `?hash=${hash}`

    return this.httpGet(`/theme${params}`).pipe(
      map((theme: Theme) => {
        return theme;
      })
    );
  }

  public getUserByToken(token): Observable<IUserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get(`${this.appConfig.getConfig().apiUrl}/auth/user`, {
      headers: httpHeaders,
    }).pipe(
      map((response: IUserModel) => {
        return response;
      })
    );
  }

  public httpGet(url: string, token: string = null, responseType: any = 'json') {

    if (token != null) {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
      return this.http.get(this.appConfig.getConfig().apiUrl + url, { headers: httpHeaders, responseType: responseType });
    }
    else
      return this.http.get(this.appConfig.getConfig().apiUrl + url);
  }

  public httpPost(url: string, body: any, token: string = null) {

    if (token != null) {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
      return this.http.post(this.appConfig.getConfig().apiUrl + url, body, { headers: httpHeaders });
    }
    else
      return this.http.post(this.appConfig.getConfig().apiUrl + url, body);
  }

  public httpPut(url: string, body: any, token: string, returnHttpResponse: boolean = false) {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });

    if (returnHttpResponse)
      return this.http.put(this.appConfig.getConfig().apiUrl + url, body, { headers: httpHeaders, observe: 'response' });
    else
      return this.http.put(this.appConfig.getConfig().apiUrl + url, body, { headers: httpHeaders });
  }

  public httpDelete(url: string, token: string) {
    if (token != null) {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
      return this.http.delete(this.appConfig.getConfig().apiUrl + url, { headers: httpHeaders });
    }
    else
      return this.http.delete(this.appConfig.getConfig().apiUrl + url);
  }
}
