import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MessageService, PrimeNGConfig } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ImageModule } from 'primeng/image';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { TabMenuModule } from 'primeng/tabmenu';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { PaginatorModule } from 'primeng/paginator';
import { SidebarModule } from 'primeng/sidebar';
import { PanelMenuModule } from 'primeng/panelmenu';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    ImageModule,
    MessagesModule,
    ProgressSpinnerModule,
    ToastModule,
    TabMenuModule,
    AvatarModule,
    AvatarGroupModule,
    TieredMenuModule,
    DialogModule,
    DynamicDialogModule,
    PaginatorModule,
    SidebarModule,
    PanelMenuModule,
  ],
  exports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    ImageModule,
    MessagesModule,
    ProgressSpinnerModule,
    ToastModule,
    TabMenuModule,
    AvatarModule,
    AvatarGroupModule,
    TieredMenuModule,
    DialogModule,
    DynamicDialogModule,
    PaginatorModule,
    SidebarModule,
    PanelMenuModule,
  ],
  providers: [
    PrimeNGConfig,
    MessageService
  ],
})
export class PrimeNgModule { }
