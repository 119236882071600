import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  HostListener,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject, takeUntil, } from 'rxjs';
import { TranslationService } from './modules/i18n/translation.service';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';

import { SplashScreenService } from './_mx/partials/layout/splash-screen/splash-screen.service';
import { TableExtendedService } from './_metronic/shared/crud-table';
import { ScreenService } from './_mx/services/screen/screen.service';
import { ThemeService } from './_primeng/services/theme/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageKeyConstants } from './_primeng/constants/storage-key.constants';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    public translateService: TranslateService,
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private tableService: TableExtendedService,
    private themeService: ThemeService,
    private screenService: ScreenService
  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  ngOnInit() {
    const id = new URLSearchParams(window.location.search).get('id');
    if (id != null) {
      sessionStorage.setItem("id", id);
    }

    this.router.events.pipe(
      takeUntil(this.destroy$)
    ).subscribe(event => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        this.tableService.setDefaults();
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });


    this.translateService.setDefaultLang('en');
    const browserLang = this.translateService.getBrowserLang();

    const localLanguagePreference = localStorage.getItem(StorageKeyConstants.SelectedLanguage);

    if (localLanguagePreference) {
      this.translateService.use(localLanguagePreference);
    }
    else {
      this.translateService.use(this.translateService.langs.find(x => x == browserLang) ? browserLang! : 'en');
    }

    const localThemePreference = localStorage.getItem(StorageKeyConstants.SelectedTheme);
    if (localThemePreference) {
      this.themeService.switchTheme(localThemePreference);
    }

    this.themeService.loadTheme();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('window:resize', [])
  public onResize(): void {
    this.screenService.resize();
  }

}
