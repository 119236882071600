// China
export const locale = {
  lang: "ch",
  data: {
    COMMON: {
      BUTTON: {
        CANCEL: "取消",
        SUBMIT: "提交",
      },
      VALIDATION: {
        SOMETHING_WENT_WRONG: "哎呀，看起来出了点问题！请再试一次。",
      },
    },
    NAVBAR: {
      HELLO: "你好",
      MY_DETAILS: "我的详细信息",
      MY_CONTACT_METHODS: "我的联系方式",
      LOGOUT: "登出",
      SELECT_LANGUAGE: "选择语言",
      MENU: "菜单",
      CLOSE: "关闭",
      SETTINGS: "设置",
      TERMSANDCONDITIONS: "条款和条件",
      LANGUAGE: {
        ENLABEL: "英语",
        CHLABEL: "中文",
        ESLABEL: "西班牙语",
        JALABEL: "日语",
        DELABEL: "德语",
        FRLABEL: "法语",
      },
      MENU_ITEM: {
        CURRENT_PLACEMENT: "当前职位",
        COMPLIANCE: "合规性",
        TIMESHEETS: "时间表",
        EXPENSES: "费用",
      },
    },
    AUTH: {
      LOGIN: {
        BUTTON: {
          SIGN_IN: "登录",
        },
        LABEL: {
          EMAIL: "电子邮件",
          PASSWORD: "密码",
          FORGOT_PASSWORD: "忘记密码?",
        },
        VALIDATION: {
          EMAIL_INVALID: "无效的电子邮件地址",
          EMAIL_MAX_LENGTH: "电子邮件不得超过 {{value}} 个字符",
          EMAIL_REQUIRED: "电子邮件是必需的",
          LOGIN_FAILED: "登录信息不正确",
          PASSWORD_MAX_LENGTH: "密码不得超过 {{value}} 个字符",
          PASSWORD_MIN_LENGTH: "密码必须至少包含 {{value}} 个字符",
          PASSWORD_REQUIRED: "密码是必需的",
        }
      },
      FORGOT_PASSWORD: {
        TITLE_DESCRIPTION: "输入您的电子邮件以重置密码",
        TITLE_FORGOTTEN_PASSWORD: "忘记密码?",
        BUTTON: {
          OK_GOT_IT: "好的，我知道了!",
        },
        LABEL: {
          EMAIL: "电子邮件",
          EMAIL_SENT_MESSAGE1: "我们已发送一封电子邮件到您指定的地址以重置您的密码。",
          EMAIL_SENT_MESSAGE2: "如果您没有收到，请检查您的垃圾邮件文件夹，并确保输入的电子邮件地址正确。",
          EMAIL_SENT_TITLE: "恢复邮件已发送!",
          SUCCESS: "您的账户已成功重置。",
        },
        VALIDATION: {
          EMAIL_INVALID: "无效的电子邮件地址",
          EMAIL_MAX_LENGTH: "电子邮件不得超过 {{value}} 个字符",
          EMAIL_REQUIRED: "电子邮件是必需的",
        }
      },
      RESET_PASSWORD: {
        TITLE_DESCRIPTION: "输入新密码的详细信息",
        TITLE_RESET_PASSWORD: "重置密码",
        BUTTON: {

        },
        LABEL: {
          CONFIRM_NEW_PASSWORD: "确认新密码",
          CREATE_NEW_PASSWORD: "创建新密码",
          OLD_PASSWORD: "旧密码",
          PASSWORD: "密码",
        },
        VALIDATION: {
          PASSWORDS_DONT_MATCH: "'密码'和'确认密码'不匹配",
          PASSWORD_MAX_LENGTH: "密码不得超过 {{value}} 个字符",
          PASSWORD_MIN_LENGTH: "密码必须至少包含 {{value}} 个字符",
          PASSWORD_PATTERN: "必须包含大小写字母、数字和以下之一：-+_!@#$%^&*.,?",
          PASSWORD_REQUIRED: "密码是必需的",
          REGISTRATION_DETAILS_INCORRECT: "注册详情不正确"
        }
      }
    },
  }
};
